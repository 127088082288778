<template>
  <v-form v-model="valid" ref="patientStepTwoForm">
    <p class="text-center question-title">
      {{ $vuetify.lang.t("$vuetify.main.patientReg.stepTwo.selectThemes") }}
    </p>
    <v-container>
      <div v-for="(events, index) in psychologistEvents" v-bind:key="index">
        <p class="ma-0 pt-0 font-weight-medium">
          {{
            $vuetify.lang.t(
              `$vuetify.main.psychologistDashboard.specialization.${index}`
            )
          }}
        </p>
        <div class="events__wrapper">
          <v-checkbox
            v-ripple
            @change="handleChangeEvents(index, i)"
            v-for="(event, i) in events"
            ripple
            v-bind:label="event"
            :key="i"
            v-bind:value="i"
            :hide-details="true"
            class="item"
          >
          </v-checkbox>
        </div>
        <v-divider
          v-if="index === 'life_event_problems'"
          class="mt-2 mb-5"
        ></v-divider>
        <p
          class="font-weight-medium pb-2"
          v-if="index === 'life_event_problems'"
        >
          {{ $vuetify.lang.t("$vuetify.main.patientReg.stepTwo.whatDiscuss") }}
        </p>
      </div>
    </v-container>
  </v-form>
</template>

<script>
import Vue from "vue";

export default {
  name: "stepTwo",
  data() {
    return {
      valid: false,

      life_event_problems: [],
      mental_health_condition_problems: [],
      relationship_problems: [],
      career_problems: [],

      psychologistEvents: null,

      // lifeEventsItems1: [],
      // lifeEventsItems2: [],
      // discussionTopics3: [],
      // discussionTopics4: [],
      // discussionTopics5: [],
      // discussionTopics6: [],
      // discussionTopics7: [],
      // discussionTopics8: [],
    };
  },
  created() {
    this.$eventHub.$on("patient-step-two-form_validate-form", this.validate);
    this.$store.dispatch("user/getPsychologistFilter").then((response) => {
      this.psychologistEvents = response.data.data;
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("patient-step-two-form_validate-form");
  },
  mounted() {},
  methods: {
    handleChangeEvents(index, value) {
      switch (index) {
        case "life_event_problems":
          this.handleItemInArray(value, this.life_event_problems);
          break;
        case "career_problems":
          this.handleItemInArray(value, this.career_problems);
          break;
        case "mental_health_condition_problems":
          this.handleItemInArray(value, this.mental_health_condition_problems);
          break;
        case "relationship_problems":
          this.handleItemInArray(value, this.relationship_problems);
          break;
      }
    },
    handleItemInArray(value, array) {
      let itemIndex = array.indexOf(value);
      itemIndex === -1 ? array.push(value) : array.splice(itemIndex, 1);
    },
    async validate() {
      this.valid =
        this.life_event_problems.length +
        this.mental_health_condition_problems.length +
        this.relationship_problems.length +
        this.career_problems.length;
      if (this.valid) {
        this.$store.dispatch("user/setPatientGeneralQuestions", {
          life_event_problems: this.life_event_problems,
          mental_health_condition_problems:
            this.mental_health_condition_problems,
          relationship_problems: this.relationship_problems,
          career_problems: this.career_problems,
        });

        let payload = {
          general_questions:
            this.$store.getters["user/getPatientGeneralQuestions"],
          chosenPsychologist: this.$store.getters[
            "user/getChosenPsychologistId"
          ]
            ? this.$store.getters["user/getChosenPsychologistId"]
            : "",
        };
        await this.$store
          .dispatch("user/requestListPsychologists", payload)
          .finally(() => {
            this.$eventHub.$emit("switch-patient-registration-tab", 3);
          });
      } else {
        Vue.swal({
          target: document.getElementById("main"),
          text: this.$vuetify.lang.t(
            "$vuetify.main.patientReg.stepTwo.warning"
          ),
          toast: true,
          position: "top-right",
          icon: "error",
          timer: 4000,
          showConfirmButton: false,
        });
      }
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.events__wrapper {
  column-count: 2;
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.item {
  padding: 8px;
  margin: -4px -4px;
  page-break-inside: avoid;
}

.question-title {
  font-size: 1.6em !important;
  font-weight: 500;
  color: $primary;
}
</style>
